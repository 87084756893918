var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "d-flex align-center",
    attrs: {
      "fluid": "",
      "fill-height": ""
    }
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    staticClass: "text-center pa-4 pa-md-8"
  }, [_c('h1', {
    staticClass: "text-h5 text-md-h4"
  }, [_vm._v("Logger inn på Min Side")]), _c('div', {
    staticClass: "my-4 my-md-8"
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "color": "primary"
    }
  })], 1), _c('div', [_c('v-btn', {
    staticClass: "mr-2",
    attrs: {
      "to": "/",
      "replace": "",
      "small": ""
    }
  }, [_vm._v("Klikk her")]), _vm._v(" hvis du ikke blir videresendt automatisk. ")], 1)])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }