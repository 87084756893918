
import { useRouter } from "@/shared/useHelpers";
import { defineComponent, onMounted } from "@vue/composition-api";

export default defineComponent({
  name: "LoginCallback",
  setup() {
    const router = useRouter();
    onMounted(() => {
      setTimeout(() => {
        // MSAL will usually redirect automatically before this component even
        // loads, but as a fallback we're redirecting the user after a small delay
        router.replace("/");
      }, 2000);
    });
  },
});
